import { useMemo } from 'react';

import { type Organization } from '../../types';
import { hexToRgb } from '../../utils/css';
import { useOrgMasqueradeFallback } from '../GameV2/apis/OrgMasqueradeFallback';
import { ColorFormatter } from './ColorFormatter';
import { useLogoBrandColor } from './useLogoBrandColor';

interface UseOrgBrandColorOptions {
  fallbackColor?: string;
  format?: 'hex' | 'rgba' | 'hsla';
  organization?: Organization;
}

type OrgBrandColorSource = 'settings' | 'logo' | 'fallback';

export function useOrgBrandColor(options: UseOrgBrandColorOptions = {}) {
  const { fallbackColor = '#FBB707', format = 'hex', organization } = options;

  const fallbackOrg = useOrgMasqueradeFallback();
  const org = useMemo(
    () => organization ?? fallbackOrg,
    [organization, fallbackOrg]
  );

  const brandColorHex = org?.settings?.brandColor;
  const { data: logoColorFormatter } = useLogoBrandColor(org, fallbackColor);

  return useMemo(() => {
    let baseFormatter: ColorFormatter | null = null;
    let source: OrgBrandColorSource = 'fallback';

    if (brandColorHex) {
      baseFormatter = new ColorFormatter([...hexToRgb(brandColorHex)]);
      source = 'settings';
    } else if (logoColorFormatter) {
      baseFormatter = logoColorFormatter;
      source = 'logo';
    } else {
      baseFormatter = new ColorFormatter([...hexToRgb(fallbackColor)]);
    }

    let color: string;

    if (baseFormatter) {
      switch (format) {
        case 'hex':
          color = baseFormatter.cssHex();
          break;
        case 'rgba':
          color = baseFormatter.cssRGBA();
          break;
        case 'hsla':
          color = baseFormatter.cssHSLA();
          break;
        default:
          color = fallbackColor;
          break;
      }
    } else {
      color = fallbackColor;
    }

    return {
      color,
      source,
      formatter: baseFormatter,
    };
  }, [brandColorHex, logoColorFormatter, fallbackColor, format]);
}
