import { forwardRef } from 'react';

import { MediaFormatVersion } from '@lp-lib/media';

import { type Organization } from '../../../types';
import { MediaUtils } from '../../../utils/media';
import { LunaParkLogo } from '../../icons/LogoIcon';
import { useOrgBrandColor } from '../../VenueOrgLogoAverageColor/useOrgBrandColor';
import { useOrgMasqueradeFallback } from '../apis/OrgMasqueradeFallback';

function OrganizationLogo(
  props: {
    organization: Organization;
  } & TrainingLogoProps
) {
  const { organization, withBrandColor = true } = props;

  const logoSrc = MediaUtils.PickMediaUrl(organization.logo, {
    priority: [MediaFormatVersion.SM],
  });

  const { color } = useOrgBrandColor({
    organization,
    fallbackColor: '#FFFFFF',
  });

  return (
    <div className='w-full flex items-center gap-1'>
      {logoSrc && (
        <div className='flex-none w-6 h-6 rounded-md overflow-hidden'>
          <img src={logoSrc} alt='' className='w-full h-full object-cover' />
        </div>
      )}
      <p
        className='flex-1 truncate tracking-[0.15px]'
        style={{ color: withBrandColor ? color : 'white' }}
      >
        {organization.name}
      </p>
    </div>
  );
}

export interface TrainingLogoProps {
  withBrandColor?: boolean;
}

export const TrainingLogo = forwardRef<HTMLDivElement, TrainingLogoProps>(
  (props, ref) => {
    const org = useOrgMasqueradeFallback();

    return (
      <div ref={ref} className='w-full h-6'>
        {org ? (
          <OrganizationLogo organization={org} {...props} />
        ) : (
          <LunaParkLogo className='h-full w-auto' />
        )}
      </div>
    );
  }
);
